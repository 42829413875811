import _ from "lodash";
import moment from "moment";

export default {
  /**
   * Affecte les valeurs de l'objet source si les propri�t�s existent dans l'objet de destination.
   * @param {*} destionationObject
   * @param {*} sourceObject
   * @returns retourne l'objet de destionation.
   */
  assignOnlyExisting(destionationObject, sourceObject) {
    return _.assign(destionationObject, _.pick(sourceObject, _.keys(destionationObject)));
  },
  isDefined(object) {
    return object !== null && object !== undefined;
  },
  arrayIsNotEmpty(object) {
    return this.isDefined(object) && Array.isArray(object) && object.length > 0;
  },
  stringIsNullOrEmpty(string) {
    return !this.isDefined(string) || string === "";
  },
  isEmpty(object) {
    return Object.keys(object).length === 0;
  },
  formatDate(date) {
    return moment.utc(date).local().format("DD/MM/YYYY");
  },
  formatMoney(value, zeroTextDisplayed = "") {
    if (this.isDefined(value)) {
      return !this.stringIsNullOrEmpty(zeroTextDisplayed) && parseFloat(value) <= 0
        ? zeroTextDisplayed
        : value.toLocaleString("fr-FR", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
          });
    }

    return value;
  },
  formatPercent(value, zeroTextDisplayed = "") {
    if (this.isDefined(value)) {
      return !this.stringIsNullOrEmpty(zeroTextDisplayed) && parseFloat(value) <= 0
        ? zeroTextDisplayed
        : (value / 100).toLocaleString("fr-FR", {
            style: "percent",
            minimumFractionDigits: 0,
          });
    }

    return value;
  },
  capitalizeFirstCharacter(value) {
    return value.length > 1 ? value[0].toUpperCase() + value.substring(1) : value.toUpperCase();
  },
};
