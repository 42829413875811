<template>
  <div class="home">
    <div class="container-fluid">
      <!-- <Transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut"> -->
      <div class="personnal-informations-container hidden-print">
        <div class="wrapper">
          <UserInputForm @compute="processUserInput" @print="print" />
        </div>
      </div>
      <!-- </Transition> -->

      <Transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
        <template v-if="displayResult">
          <div id="simulation-result" class="simulation-result-container">
            <div class="wrapper">
              <div class="alert alert-info mb-4 hidden-print">
                <div class="inner">
                  <div class="ampoule">
                    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0C3.14 0 0 3.14 0 7C0 9.38 1.19 11.47 3 12.74V15C3 15.55 3.45 16 4 16H10C10.55 16 11 15.55 11 15V12.74C12.81 11.47 14 9.38 14 7C14 3.14 10.86 0 7 0ZM4 19C4 19.55 4.45 20 5 20H9C9.55 20 10 19.55 10 19V18H4V19ZM9 11.7L9.85 11.1C11.2 10.16 12 8.63 12 7C12 4.24 9.76 2 7 2C4.24 2 2 4.24 2 7C2 8.63 2.8 10.16 4.15 11.1L5 11.7V14H9V11.7Z" fill="white"></path>
                    </svg>
                  </div>
                  <p>
                    Le tableau ci-contre présente une estimation des garanties sur la base des éléments communiqués.<br />
                    Les paramètres de calcul utilisés sont des moyennes.
                  </p>
                  <p>En cas de sinistre, chaque situation personnelle devra être analysée sur la base de votre dernier bulletin de paie afin de déterminer le montant exact de l'indemnisation due au titre du contrat.</p>
                  <p>Votre rémunération mensuelle brute à considérer correspond au "Montant total du mois à payer" présent sur <a href="https://www.vivinter.fr/wp-content/uploads/2024/11/ExemplesBulletindePaie.pdf" target="_blank" rel="noreferrer">votre fiche de paie</a> auquel il faut déduire la Participation PSC (15€), le remboursement Domicile-Travail et le forfait télétravail. Elle tient compte des primes à caractère permanent conformément à l’accord interministériel du 20 octobre 2023.</p>
                  <p>Les données renseignées dans le simulateur ne seront pas enregistrées, elles servent uniquement à vous proposer une simulation à titre informatif.</p>
                </div>
              </div>
              <DisplayResult :userInput="userInput" :resultats="displayResult" :fullDisplay="fullDisplay" />
            </div>
          </div>
        </template>
      </Transition>
    </div>
  </div>
</template>

<script>
import objectHelper from "@/classes/helpers/objectHelper";
import simulationService from "@/classes/services/simulationService";
import UserInformations from "@/classes/dto/userInformations";
import UserInputForm from "@/components/UserInputForm";
import DisplayResult from "@/components/DisplayResult";

export default {
  name: "HomeView",
  components: {
    UserInputForm,
    DisplayResult,
  },
  data() {
    return {
      objectHelper,
      userInput: new UserInformations(),
      displayResult: undefined,
      fullDisplay: false,
    };
  },
  methods: {
    processUserInput(event) {
      this.userInput = new UserInformations(event);
      this.displayResult = simulationService.compute(event);
    },
    print() {
      document.title = "Vivinter SPM - Ma simulation";
      this.fullDisplay = true;

      setTimeout(() => {
        window.print();

        setTimeout(() => {
          document.title = "Mise en place SPM Agents - Vivinter SPM";
          this.fullDisplay = false;
        }, 500);
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

$wrapper-base-width: 1000px;
$bs-gutter-x: 1.5rem;
$bs-gutter-y: 0;

.personnal-informations-container,
.simulation-result-container {
  margin-top: calc($bs-gutter-y * -1);
  margin-right: calc($bs-gutter-x * -0.5);
  margin-left: calc($bs-gutter-x * -0.5);

  .wrapper {
    max-width: $wrapper-base-width;
    margin: 0 auto;
    padding: 1.5rem 0;

    @media (max-width: $screen-md-max) {
      max-width: $screen-md-max !important;
    }

    @media (max-width: $screen-sm-max) {
      margin: 0rem auto !important;
      padding: 2rem 0;
      border-radius: 0 !important;

      max-width: $screen-sm-max !important;
    }

    @media (max-width: $screen-xs-max) {
      max-width: $screen-xs-max !important;
    }
  }
}

.personnal-informations-container {
  .wrapper {
    margin-top: 1rem;
    margin-bottom: 10px;
    background: white url("@/assets/images/fond_vivinter.svg") right center no-repeat;
    background-size: auto 100%;
    @include shadow(5px);

    @media (max-width: $wrapper-base-width) {
      margin-top: 0;
      box-shadow: none;
      border-radius: 0;
    }

    @media (max-width: $screen-xs-max) {
      background: $gradient-siaci;
    }
  }
}

.simulation-result-container {
  .alert {
    $sizeAmpoule: 2.5rem;
    $offsetBorder: 3px;
    $radiusAlert: 5px;

    position: relative;
    border-radius: 0;
    background-color: $background-color-default;
    padding: 0;

    &:before {
      content: "";
      display: block;
      position: absolute;
      inset: calc($offsetBorder * -1);
      background: $gradient-siaci;
      border-radius: $radiusAlert + $offsetBorder;
      // opacity: 0;
      // visibility: hidden;
      opacity: 1;
      visibility: visible;
    }

    .inner {
      position: relative;
      z-index: 1;
      background-color: $background-color-default;
      height: 100%;
      padding: 1rem;
      padding-left: calc(1rem + $sizeAmpoule / 2);
      border-radius: $radiusAlert;

      * {
        color: $secondary;
      }

      .ampoule {
        position: absolute;
        width: $sizeAmpoule;
        height: $sizeAmpoule;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-5;
        border-radius: 100%;
        left: calc((($sizeAmpoule + $offsetBorder) / 2) * -1);
        top: calc((100% - $sizeAmpoule) / 2);
      }
    }

    &:hover {
      // &::before {
      //   opacity: 1;
      //   visibility: visible;
      // }

      .inner {
        .ampoule {
          @include animate(flip);
        }
      }
    }

    @media (max-width: calc($screen-md-max + $sizeAmpoule + 1rem)) {
      margin: 0 $offsetBorder;
      margin-left: calc(1rem + $sizeAmpoule / 2);
    }

    @media (max-width: calc($screen-xs-max + $sizeAmpoule + 1rem)) {
      margin-left: calc(0.75rem + $sizeAmpoule / 2) !important;
    }
  }

  .wrapper {
    $colToggleWidth: 1.25rem;

    @media (max-width: calc($screen-md-max + $colToggleWidth + 1rem)) {
      margin-right: 1rem;
      margin-left: calc($colToggleWidth / 2);
    }
  }
}
</style>
