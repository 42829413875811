export const FONCTIONNAIRE = 0;
export const CONTRACTUEL = 1;

export const PROFILS = [
  { id: FONCTIONNAIRE, name: "fonctionnaire" },
  { id: CONTRACTUEL, name: "contractuel" },
];

export const BASE = 0;
export const PRIMES = 1;

export const REMUNERATIONS = [
  { id: BASE, name: "traitement de base" },
  { id: PRIMES, name: "primes" },
];

export const PARAMETRAGE_PRESTATIONS = [
  { idProfil: FONCTIONNAIRE, pmss: 3864.0, montantMiniCapitalDeces: 13600 },
  { idProfil: CONTRACTUEL, pmss: 3864.0, montantMiniCapitalDeces: 13600 },
];

export const PARAMETRAGE_REMUNERATIONS = [
  { idProfil: FONCTIONNAIRE, idRemuneration: BASE, coeffBrutNet: 0.8 },
  { idProfil: FONCTIONNAIRE, idRemuneration: PRIMES, coeffBrutNet: 0.85 },

  { idProfil: CONTRACTUEL, idRemuneration: BASE, coeffBrutNet: 0.8 },
  { idProfil: CONTRACTUEL, idRemuneration: PRIMES, coeffBrutNet: 0.8 },
];
