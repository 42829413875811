import objectHelper from "@/classes/helpers/objectHelper";
import * as COTISATIONS from "@/classes/parametrage/cotisations";

export default {
  /*
   * Récupération du paramétrage des garanties correspondant à la tranche d'âge et au type de garantie
   */
  getTauxCotisations(idTrancheAge, idGarantie) {
    var result = 0;

    var parametrages = COTISATIONS.PARAMETRAGE.filter((x) => x.idTrancheAge == idTrancheAge);
    if (objectHelper.arrayIsNotEmpty(parametrages) && parametrages.some((x) => x.idGarantie == idGarantie)) {
      result = parametrages.find((x) => x.idGarantie == idGarantie).taux;
    }

    // Le taux est un pourcentage
    result = result / 100;

    return result;
  },
};
