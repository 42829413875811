<template>
  <div class="component">
    <form ref="inputForm" @submit.prevent="computeUserInput">
      <div class="row mb-4">
        <div class="col">
          <div class="title">Simulateur personnalisé pour votre prévoyance complémentaire</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="row">
              <label class="col col-form-label">Votre rémunération mensuelle brute</label>
              <div class="col-sm-6 col-md-5 col-lg-4">
                <input type="text" :value="formatedSalaire" class="form-control" min="0" :class="{ validationError: v$.personalInformationValues.salaireBrut.$error }" @input="updateSalaireBrut" @change="computeUserInput" required />
              </div>
            </div>
            <div class="row mt-1">
              <label class="col col-form-label regular">dont primes</label>
              <div class="col-sm-6 col-md-5 col-lg-4">
                <input type="text" :value="formatedPrimes" class="form-control regular" min="0" @input="updatePrimes" @change="computeUserInput" required />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col col-form-label">Votre tranche d'âge</label>
              <div class="col-sm-6 col-md-5 col-lg-4">
                <select class="form-select" :class="{ validationError: v$.personalInformationValues.idTrancheAge.$error }" v-model="personalInformationValues.idTrancheAge" @change="computeUserInput" required>
                  <option :value="undefined" disabled selected>Choisissez une tranche d'âge dans la liste suivante</option>
                  <option v-for="tranche in tranchesAge" :key="tranche.id" :value="tranche.id">{{ objectHelper.capitalizeFirstCharacter(tranche.name) }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col col-form-label">Nombre d'enfant(s) à charge fiscale de moins de 27 ans</label>
              <div class="col-sm-7 col-md-5 col-lg-4">
                <input type="number" class="form-control" v-model="personalInformationValues.nombreEnfantsMoins27Ans" min="0" @change="computeUserInput" required />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <label class="col col-form-label">Êtes-vous ?</label>
              <div class="col-sm-6 col-md-5 col-lg-4">
                <div class="input-group no-wrap">
                  <div class="custom-control custom-radio">
                    <input type="radio" name="radioIsContractuel" class="custom-control-input" v-model="personalInformationValues.isContractuel" :value="true" @change="computeUserInput" />
                    <label class="custom-control-label">Contractuel</label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" name="radioIsContractuel" class="custom-control-input" v-model="personalInformationValues.isContractuel" :value="false" @change="computeUserInput" />
                    <label class="custom-control-label">Fonctionnaire</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row justify-content-end mt-3">
      <div class="col-sm-6 col-md-6 col-lg-5">
        <div class="button-container">
          <Transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
            <button class="btn btn-primary hidden-print" @click="print" v-if="showPrintButton">
              <img src="@/assets/images/imprimante.svg" width="32" />
            </button>
          </Transition>
          <button type="submit" class="btn btn-primary" @click="computeUserInput(event, true)">
            Lancer la simulation
            <img src="@/assets/images/fleche_droite.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { minValue, required } from "@vuelidate/validators";

import objectHelper from "@/classes/helpers/objectHelper";
import { TRANCHES_AGE } from "@/classes/parametrage/tranchesAge";
import UserInformations from "@/classes/dto/userInformations";

const greaterThanZero = (value) => value > 0;

export default {
  name: "UserInputForm",
  emits: ["compute", "print"],
  data() {
    return {
      objectHelper,
      tranchesAge: [],
      personalInformationValues: new UserInformations(),
      buttonComputeClickedAtLeastOneTime: false,
      showPrintButton: false,
    };
  },
  setup: () => {
    return { v$: useVuelidate() };
  },
  created() {
    this.tranchesAge = TRANCHES_AGE;
  },
  validations() {
    return {
      personalInformationValues: {
        idTrancheAge: { required },
        salaireBrut: { required, greaterThanZero },
        primes: { required, minValue: minValue(0) },
      },
    };
  },
  mounted() {
    if (window.location.hostname == "localhost") {
      setTimeout(() => {
        this.buttonComputeClickedAtLeastOneTime = true;
        this.computeUserInput();
      }, 1000);
    }
  },
  computed: {
    formatedSalaire() {
      return this.personalInformationValues.salaireBrut.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
      });
    },
    formatedPrimes() {
      return this.personalInformationValues.primes.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
      });
    },
  },
  methods: {
    print() {
      this.$emit("print");
    },
    updateSalaireBrut(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.personalInformationValues.salaireBrut = parseFloat(value) || 0;
    },
    updatePrimes(event) {
      const value = event.target.value.replace(/[^\d]/g, "");
      this.personalInformationValues.primes = parseFloat(value) || 0;
    },
    async computeUserInput(event, buttonWasClicked = false) {
      if (buttonWasClicked) {
        this.buttonComputeClickedAtLeastOneTime = true;
      }

      if (this.buttonComputeClickedAtLeastOneTime && (await this.v$.$validate()) === true) {
        this.$emit("compute", this.personalInformationValues);
        this.showPrintButton = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

.component {
  $sideMargin: 5rem;

  form {
    padding: 1.5rem 0;
    margin: 0 $sideMargin;
    border-radius: 5px;
    color: $secondary;
    background-color: white;

    @media (max-width: $screen-md-max) {
      padding: 0.5rem 0;
    }

    .title {
      font: normal normal normal 26px/38px "csb";
      text-align: center;

      @media (max-width: $screen-md-max) {
        padding: 0 1rem;
        margin-top: 0.5rem;
        line-height: 30px;
      }
    }

    .form-group {
      padding: 0 $sideMargin;
    }
  }

  @media (max-width: $screen-md-max) {
    $sideMargin: 1rem;

    form {
      margin: 0 $sideMargin;

      .form-group {
        padding: 0 $sideMargin;
      }
    }
  }

  .button-container {
    margin: 0;
    margin-right: 5rem;
    display: flex;

    @media (max-width: $screen-md-max) {
      $sideMargin: 1rem;
      margin: 0 $sideMargin;
    }

    button {
      $size: 48px;
      height: $size;
      text-align: left;
      width: $size;

      &:not(.hidden-print) {
        width: 100% !important;

        &.btn-primary {
          font: normal normal normal 16px/22px "csb";
          letter-spacing: 0px;
          color: white;
          border-color: white;
          background-color: transparent;
        }

        img {
          float: right;
        }
      }

      &.hidden-print {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        border-color: white;
        background-color: transparent;

        img {
          $ratio: 0.7;
          max-width: calc($size * $ratio);
          max-height: calc($size * $ratio);
        }
      }
    }
  }
}
</style>
