import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueDebounce from "vue-debounce";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

// animate.css
import "animate.css/animate.min.css";

// Fichiers de style globaux
import "@/assets/styles/_fonts.scss";
import "@/assets/styles/_variables.scss";
import "@/assets/styles/_form.scss";
import "@/assets/styles/_mixins.scss";
import "@/assets/styles/_print.scss";
import "@/assets/styles/_app.scss";

// Directives perso
import $bus from "@/classes/helpers/eventBus";

const vueApp = createApp(App).use(store).use(router);
vueApp.config.globalProperties.$bus = $bus;
vueApp.use(VueDebounce, { listenTo: "click", defaultTime: "300ms" });

// Gestion d'erreurs potentielles
// vueApp.config.errorHandler = (err, vm, info) => {
//   if (info.includes("created")) {
//     router.push({ name: "403" });
//   }
//   console.error(err);
//   return;
// };

vueApp.mount("#simulateur-spm");
