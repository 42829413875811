import * as OPTIONS from "@/classes/parametrage/options";

export const TYPE_DECES = 1;
export const TYPE_MALADIE = 2;
export const TYPE_INVALIDITE = 3;

export const CAPITAL_DECES = 1;
export const RENTE_EDUCATION = 2;
export const MALADIE_ORDINAIRE = 3;
export const MALADIE_LONGUE_MALADIE_GRAVE = 4;
export const MALADIE_LONGUE_DUREE = 5;
export const INVALIDITE_TITULAIRES = 6;
export const INVALIDITE_CONTRACTUELS = 7;

export const DE_0_A_18 = 1;
export const DE_19_A_27 = 2;
export const PLEIN_TRAITEMENT = 3;
export const DEMI_TRAITEMENT = 4;
export const CATEGORIE_1 = 5;
export const CATEGORIE_2_3 = 6;

export const TIB_NBI = 1;
export const PRIMES_INDEMNITES = 2;

export const PARAMETRAGE = {
  types: [
    {
      idType: TYPE_MALADIE,
      garanties: [
        /*
         *  MALADIE : MALADIE ORDINAIRE
         */
        {
          idGarantie: MALADIE_ORDINAIRE,
          idCritere: PLEIN_TRAITEMENT,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 1 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1 },
            { idOption: OPTIONS.OPTION_1, taux: 1 },
            { idOption: OPTIONS.OPTION_2, taux: 1 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
        {
          idGarantie: MALADIE_ORDINAIRE,
          idCritere: PLEIN_TRAITEMENT,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 1 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1 },
            { idOption: OPTIONS.OPTION_1, taux: 1 },
            { idOption: OPTIONS.OPTION_2, taux: 1 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
        {
          idGarantie: MALADIE_ORDINAIRE,
          idCritere: DEMI_TRAITEMENT,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.5 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.5 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
        {
          idGarantie: MALADIE_ORDINAIRE,
          idCritere: DEMI_TRAITEMENT,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.5 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.5 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },

        /*
         *  MALADIE : LONGUE MALADIE / GRAVE MALADIE
         */
        {
          idGarantie: MALADIE_LONGUE_MALADIE_GRAVE,
          idCritere: PLEIN_TRAITEMENT,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 1 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1 },
            { idOption: OPTIONS.OPTION_1, taux: 1 },
            { idOption: OPTIONS.OPTION_2, taux: 1 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
        {
          idGarantie: MALADIE_LONGUE_MALADIE_GRAVE,
          idCritere: PLEIN_TRAITEMENT,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.33 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1 },
            { idOption: OPTIONS.OPTION_1, taux: 1 },
            { idOption: OPTIONS.OPTION_2, taux: 1 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
        {
          idGarantie: MALADIE_LONGUE_MALADIE_GRAVE,
          idCritere: DEMI_TRAITEMENT,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.6 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.8 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
        {
          idGarantie: MALADIE_LONGUE_MALADIE_GRAVE,
          idCritere: DEMI_TRAITEMENT,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.6 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.8 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },

        /*
         *  MALADIE : LONGUE DUREE
         */
        {
          idGarantie: MALADIE_LONGUE_DUREE,
          idCritere: PLEIN_TRAITEMENT,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 1 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1 },
            { idOption: OPTIONS.OPTION_1, taux: 1 },
            { idOption: OPTIONS.OPTION_2, taux: 1 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
        {
          idGarantie: MALADIE_LONGUE_DUREE,
          idCritere: PLEIN_TRAITEMENT,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0 },
            { idOption: OPTIONS.OPTION_1, taux: 1 },
            { idOption: OPTIONS.OPTION_2, taux: 1 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
        {
          idGarantie: MALADIE_LONGUE_DUREE,
          idCritere: DEMI_TRAITEMENT,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.5 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.5 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
        {
          idGarantie: MALADIE_LONGUE_DUREE,
          idCritere: DEMI_TRAITEMENT,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 1 },
          ],
        },
      ],
    },
    {
      idType: TYPE_INVALIDITE,
      garanties: [
        /*
         *  INVALIDITE : TITULAIRES
         */
        {
          idGarantie: INVALIDITE_TITULAIRES,
          idCritere: CATEGORIE_1,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.4 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.5 },
            { idOption: OPTIONS.OPTION_1, taux: 0.5 },
            { idOption: OPTIONS.OPTION_2, taux: 0.6 },
            { idOption: OPTIONS.OPTION_3, taux: 0.6 },
          ],
        },
        {
          idGarantie: INVALIDITE_TITULAIRES,
          idCritere: CATEGORIE_1,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.4 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.5 },
            { idOption: OPTIONS.OPTION_1, taux: 0.5 },
            { idOption: OPTIONS.OPTION_2, taux: 0.6 },
            { idOption: OPTIONS.OPTION_3, taux: 0.6 },
          ],
        },
        {
          idGarantie: INVALIDITE_TITULAIRES,
          idCritere: CATEGORIE_2_3,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.7 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.8 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 0.9 },
          ],
        },
        {
          idGarantie: INVALIDITE_TITULAIRES,
          idCritere: CATEGORIE_2_3,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.7 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.8 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 0.9 },
          ],
        },

        /*
         *  INVALIDITE : CONTRACTUELS
         */
        {
          idGarantie: INVALIDITE_CONTRACTUELS,
          idCritere: CATEGORIE_1,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.3 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.5 },
            { idOption: OPTIONS.OPTION_1, taux: 0.5 },
            { idOption: OPTIONS.OPTION_2, taux: 0.6 },
            { idOption: OPTIONS.OPTION_3, taux: 0.6 },
          ],
        },
        {
          idGarantie: INVALIDITE_CONTRACTUELS,
          idCritere: CATEGORIE_1,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.3 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.5 },
            { idOption: OPTIONS.OPTION_1, taux: 0.5 },
            { idOption: OPTIONS.OPTION_2, taux: 0.6 },
            { idOption: OPTIONS.OPTION_3, taux: 0.6 },
          ],
        },
        {
          idGarantie: INVALIDITE_CONTRACTUELS,
          idCritere: CATEGORIE_2_3,
          idTypeRemuneration: TIB_NBI,
          plafondFonctionnaire: 1600,
          plafondContractuel: 1600,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.5 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.8 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 0.9 },
          ],
        },
        {
          idGarantie: INVALIDITE_CONTRACTUELS,
          idCritere: CATEGORIE_2_3,
          idTypeRemuneration: PRIMES_INDEMNITES,
          plafondFonctionnaire: 850,
          plafondContractuel: 800,
          parametrage: [
            { idOption: OPTIONS.OBLIGATION_STATUTAIRE, taux: 0.5 },
            { idOption: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 0.8 },
            { idOption: OPTIONS.OPTION_1, taux: 0.8 },
            { idOption: OPTIONS.OPTION_2, taux: 0.9 },
            { idOption: OPTIONS.OPTION_3, taux: 0.9 },
          ],
        },
      ],
    },
  ],
};
