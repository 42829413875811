<template>
  <template v-for="resultat in resultats" :key="resultat">
    <template v-if="objectHelper.isDefined(resultat.garantie) && resultats.some((x) => x.garantie.id == resultat.garantie.id)">
      <div class="col">
        <DisplayResultColumn :cssClass="resultat.garantie.cssClass + ' result-example'">
          <template v-slot:maladie>
            <div class="line">
              <div class="montant">{{ objectHelper.formatMoney(getCotisationTotale(resultat.garantie.id)) }}</div>
            </div>
            <div class="line">
              <div class="montant">{{ objectHelper.formatMoney(getRemunerationTotale(resultat.garantie.id)) }}</div>
            </div>
            <div class="line">
              <div class="montant">{{ objectHelper.formatMoney(getPrestationsRegimePrevoyance(resultat.garantie.id)) }}</div>
            </div>
          </template>
        </DisplayResultColumn>
      </div>
    </template>
  </template>
</template>

<script>
import objectHelper from "@/classes/helpers/objectHelper";
import UserInformations from "@/classes/dto/userInformations";
import * as GARANTIES from "@/classes/parametrage/options";
import DisplayResultColumn from "./DisplayResultColumn.vue";

export default {
  name: "DisplayResultExempleMaladie",
  components: { DisplayResultColumn },
  data() {
    return {
      GARANTIES,
      objectHelper,
    };
  },
  props: {
    userInput: {
      type: UserInformations,
      default: new UserInformations(),
    },
    resultats: {
      type: Array,
      default: undefined,
    },
  },
  methods: {
    getResultat(idGarantie) {
      return this.resultats.find((x) => x.garantie.id == idGarantie);
    },
    getCotisationTotale(idGarantie) {
      return Math.round(this.getResultat(idGarantie).cotisationMensuelleApresEmployeur * 12);
    },
    getRemunerationTotale(idGarantie) {
      var pleinTraitement = this.getResultat(idGarantie).maladie.ordinaire.pleinTraitement;
      var demiTraitement = this.getResultat(idGarantie).maladie.ordinaire.demiTraitement;
      return Math.round(pleinTraitement * 3 + demiTraitement * 9);
    },
    getPrestationsRegimePrevoyance(idGarantie) {
      var remunerationTotaleObligationStatutaire = this.getRemunerationTotale(GARANTIES.OBLIGATION_STATUTAIRE);
      var remunerationTotaleGarantie = this.getRemunerationTotale(idGarantie);
      return remunerationTotaleGarantie - remunerationTotaleObligationStatutaire;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";
</style>
