<template>
  <div class="component" :class="{ 'no-toggle': !this.showToggleColumn }">
    <div class="visible-md mb-3">
      <div class="option-selector hidden-print" :class="{ 'no-toggle': !this.showToggleColumn }">
        <div class="row gx-0 mb-1">
          <div class="col">
            <div class="title">Sélectionnez les offres à comparer :</div>
          </div>
        </div>
        <div class="row gx-0">
          <div class="col">
            <div class="selector-button">
              <div class="text">Offre 1</div>
              <div
                class="toggle"
                :class="{ expand: expandOption1 }"
                @click="
                  expandOption1 = !expandOption1;
                  expandOption2 = false;
                "
                v-if="options1.length > 1"
              >
                <img src="@/assets/images/fleche_bas_sombre.svg" />
              </div>
            </div>
            <template v-if="expandOption1">
              <Transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
                <select class="form-select option option-1 mt-1" v-model="choosenOption1" @change="manageChooseOptions">
                  <option v-for="option in options1" :key="option.id" :value="option.id">{{ objectHelper.capitalizeFirstCharacter(option.name) }}</option>
                </select>
              </Transition>
            </template>
          </div>
          <div class="col">
            <div class="selector-button">
              <div class="text">Offre 2</div>
              <div
                class="toggle"
                :class="{ expand: expandOption2 }"
                @click="
                  expandOption1 = false;
                  expandOption2 = !expandOption2;
                "
                v-if="options2.length > 1"
              >
                <img src="@/assets/images/fleche_bas_sombre.svg" />
              </div>
            </div>
            <template v-if="expandOption2">
              <Transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
                <select class="form-select option option-2 mt-1" v-model="choosenOption2" @change="manageChooseOptions">
                  <option v-for="option in options2" :key="option.id" :value="option.id">{{ objectHelper.capitalizeFirstCharacter(option.name) }}</option>
                </select>
              </Transition>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="row gx-0">
      <div class="col-3">
        <DisplayResultColumn>
          <template v-slot:header>
            <div class="line start">
              <div class="visible-print cartouche">
                <img src="@/assets/images/vivinter_diot-siaci.png" />
              </div>
            </div>
            <div class="line start">&nbsp;</div>
            <div class="line start text-start">
              <div class="hidden-xs hidden-print">
                Votre cotisation <br />
                mensuelle après <br />
                déduction de la <br />
                participation <br />
                employeur
              </div>
              <div class="visible-xs visible-print">Votre cotisation mensuelle après déduction de la participation employeur</div>
            </div>
            <div class="line start">&nbsp;</div>
          </template>
          <template v-slot:capital-deces>
            <div class="line start title">Décès</div>

            <template v-if="expandCapitalDeces">
              <div class="line start subtitle">Capital décès</div>
              <template v-if="objectHelper.isDefined(userInput) && userInput.nombreEnfantsMoins27Ans > 0">
                <div class="line start subtitle">Rente mensuelle d'éducation par enfant</div>
                <div class="line start">de 0 à 18 ans</div>
                <div class="line start">de 19 à 27 ans</div>
              </template>
            </template>
          </template>
          <template v-slot:maladie>
            <div class="line start title">
              <div>
                <div class="hidden-xs hidden-print">
                  Maladie<br />
                  <small>(montant net)</small>
                </div>
                <div class="visible-xs visible-print no-wrap">Maladie (montant net)</div>
              </div>
            </div>

            <template v-if="expandMaladie">
              <div class="line start subtitle">
                <span>Congé Maladie ordinaire</span>
                <!-- <Tooltip cssClass="top">Montant mensuel <strong>net</strong></Tooltip>
                <div class="visible-xs">(*)</div> -->
              </div>
              <div class="line start">Période à plein traitement</div>
              <div class="line start">Période à demi traitement</div>

              <div class="line start subtitle">
                <span>
                  <template v-if="userInput.isContractuel">Congé de grave maladie</template>
                  <template v-else>Congé de longue maladie</template>
                </span>
                <!-- <Tooltip cssClass="top">Montant mensuel <strong>net</strong></Tooltip>
                <div class="visible-xs">(*)</div> -->
              </div>
              <div class="line start">Période à plein traitement</div>
              <div class="line start">Période à demi traitement</div>

              <template v-if="!userInput.isContractuel">
                <div class="line start subtitle">
                  <span>Congé longue durée</span>
                  <!-- <Tooltip cssClass="top">Montant mensuel <strong>net</strong></Tooltip>
                  <div class="visible-xs">(*)</div> -->
                </div>
                <div class="line start">Période à plein traitement</div>
                <div class="line start">Période à demi traitement</div>
              </template>
            </template>
          </template>
          <template v-slot:invalidite>
            <div class="line start title">
              <div class="hidden-xs hidden-print">
                Invalidité<br />
                <small>(montant brut)</small>
              </div>
              <div class="visible-xs visible-print no-wrap">Invalidité (montant brut)</div>
            </div>

            <template v-if="expandInvalidite">
              <!-- <div class="line start subtitle">
                <template v-if="userInput.isContractuel">Contractuels</template>
                <template v-else>Titulaires</template>
              </div> -->
              <div class="line start">Catégorie 1</div>
              <div class="line start">Catégorie 2 & 3</div>
            </template>
          </template>
        </DisplayResultColumn>
      </div>

      <DisplayResultOptions :user-input="userInput" :resultats="sortedResultats" :choosen-options="choosenOptions" :show-toggle-column="showToggleColumn" :expand-capital-deces="expandCapitalDeces" :expand-maladie="expandMaladie" :expand-invalidite="expandInvalidite" />

      <template v-if="showToggleColumn">
        <div class="col col-toggle" :class="{ expanded: expandCapitalDeces || expandMaladie || expandInvalidite }">
          <DisplayResultColumn>
            <template v-slot:header>
              <div class="line end"></div>
              <div class="line end"></div>
              <div class="line end"></div>
              <div class="line end"></div>
            </template>
            <template v-slot:capital-deces>
              <div class="line end title">
                <Transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
                  <template v-if="objectHelper.isDefined(userInput) && userInput.nombreEnfantsMoins27Ans > 0">
                    <div class="toggle" :class="{ expand: expandCapitalDeces }" @click="expandCapitalDeces = !expandCapitalDeces">
                      <img src="@/assets/images/fleche_bas.svg" />
                    </div>
                  </template>
                </Transition>
              </div>

              <template v-if="objectHelper.isDefined(userInput) && userInput.nombreEnfantsMoins27Ans > 0 && expandCapitalDeces">
                <div class="line end subtitle"></div>
                <div class="line end subtitle"></div>
                <div class="line end"></div>
                <div class="line end"></div>
              </template>
            </template>
            <template v-slot:maladie>
              <div class="line end title">
                <div class="toggle" :class="{ expand: expandMaladie }" @click="expandMaladie = !expandMaladie">
                  <img src="@/assets/images/fleche_bas.svg" />
                </div>
              </div>

              <template v-if="expandMaladie">
                <div class="line end subtitle"></div>
                <div class="line end"></div>
                <div class="line end"></div>

                <div class="line end subtitle"></div>
                <div class="line end"></div>
                <div class="line end"></div>

                <template v-if="!userInput.isContractuel">
                  <div class="line end subtitle"></div>
                  <div class="line end"></div>
                  <div class="line end"></div>
                </template>
              </template>
            </template>
            <template v-slot:invalidite>
              <div class="line end title">
                <div class="toggle" :class="{ expand: expandInvalidite }" @click="expandInvalidite = !expandInvalidite">
                  <img src="@/assets/images/fleche_bas.svg" />
                </div>
              </div>

              <template v-if="expandInvalidite">
                <!-- <div class="line end subtitle"></div> -->
                <div class="line end"></div>
                <div class="line end"></div>
              </template>
            </template>
          </DisplayResultColumn>
        </div>
      </template>
    </div>

    <!-- <div class="tooltip-contents visible-xs">
      <div class="row mt-5">
        <div class="col">(*) : Montant mensuel <strong>net</strong></div>
      </div>
      <div class="row">
        <div class="col">(**) : Montant mensuel <strong>brut</strong> à limiter à la rémunération totale nette mensuelle de l'agent</div>
      </div>
    </div> -->

    <template v-if="showExempleCotisation">
      <div class="row gx-0 mt-5">
        <div class="col-3">
          <DisplayResultColumn>
            <template v-slot:maladie>
              <div class="line start subtitle no-wrap">Exemple pour un congé maladie ordinaire de 12 mois</div>
              <div class="line start">Cotisation totale de l'agent sur la période</div>
              <div class="line start">Rémunération totale perçue sur la période</div>
              <div class="line start text-right" style="text-align: right">dont prestations versées par le régime prévoyance</div>
            </template>
          </DisplayResultColumn>
        </div>
        <DisplayResultExempleMaladie :user-input="userInput" :resultats="sortedResultats" />
        <div class="col col-toggle"></div>
      </div>
    </template>
  </div>
</template>

<script>
import objectHelper from "@/classes/helpers/objectHelper";
import UserInformations from "@/classes/dto/userInformations";
import DisplayResultColumn from "./DisplayResultColumn.vue";
import DisplayResultOptions from "./DisplayResultOptions.vue";
import DisplayResultExempleMaladie from "./DisplayResultExempleMaladie.vue";
import * as OPTIONS from "@/classes/parametrage/options";

export default {
  name: "DisplayResult",

  components: { DisplayResultColumn, DisplayResultOptions, DisplayResultExempleMaladie },
  data() {
    return {
      objectHelper,
      choosenOptions: [],
      choosenOption1: OPTIONS.OBLIGATION_STATUTAIRE,
      expandOption1: false,
      choosenOption2: OPTIONS.SOCLE_INTERMINISTERIEL,
      expandOption2: false,
      windowWidth: window.innerWidth,
      showToggleColumn: false,
      expandCapitalDeces: false,
      expandMaladie: false,
      expandInvalidite: false,
      showExempleCotisation: false,
    };
  },
  props: {
    userInput: {
      type: UserInformations,
      default: new UserInformations(),
    },
    resultats: {
      type: Array,
      default: undefined,
    },
    fullDisplay: {
      type: Boolean,
    },
  },
  created() {
    // Si on cache la colonne de toggle, alors on montre chaque élément
    if (!this.showToggleColumn) {
      this.expandCapitalDeces = true;
      this.expandMaladie = true;
      this.expandInvalidite = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      this.sendScrollHeightToParent();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    sortedResultats() {
      return this.resultats; //.sort((a, b) => a.garantie.id - b.garantie.id);
    },
    breakPoints() {
      var type = this.windowWidth < 576 ? "xs" : this.windowWidth < 768 ? "sm" : this.windowWidth < 992 ? "md" : this.windowWidth < 1200 ? "lg" : "xl";
      return {
        type: type,
        width: this.windowWidth,
      };
    },
    options1() {
      return OPTIONS.PARAMETRAGE.filter((x) => x.id != this.choosenOption2 && x.id < this.choosenOption2);
    },
    options2() {
      return OPTIONS.PARAMETRAGE.filter((x) => x.id != this.choosenOption1 && x.id > this.choosenOption1);
    },
  },
  methods: {
    manageChooseOptions() {
      this.expandOption1 = false;
      this.expandOption2 = false;
      this.choosenOptions = [this.choosenOption1, this.choosenOption2];
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    sendScrollHeightToParent() {
      // Event d'info pour le redimensionnement de l'iframe
      const scrollHeight = document.documentElement.scrollHeight;
      window.parent.postMessage({ height: scrollHeight }, "*");
      console.debug("window.parent.postMessage done (scrollHeight = " + scrollHeight + "px)");
    },
  },
  watch: {
    fullDisplay: {
      handler(newValue) {
        if (newValue) {
          this.choosenOptions = OPTIONS.PARAMETRAGE.map((x) => x.id);
        } else {
          if (["xs", "sm", "md"].includes(this.breakPoints.type)) {
            this.choosenOptions = [this.choosenOption1, this.choosenOption2];
          } else {
            this.choosenOptions = OPTIONS.PARAMETRAGE.map((x) => x.id);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    breakPoints: {
      handler(newValue) {
        console.debug("screen size : " + newValue.type);

        if (!this.fullDisplay && ["xs", "sm", "md"].includes(newValue.type)) {
          this.choosenOptions = [this.choosenOption1, this.choosenOption2];
        } else {
          this.choosenOptions = OPTIONS.PARAMETRAGE.map((x) => x.id);
        }

        this.sendScrollHeightToParent();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

.component {
  $colToggleWidth: 1.25rem;
  $colToggleWidthXs: 3rem;
  $colToggleWidthSm: 2rem;
  $colToggleWidthMd: 2rem;

  @media (min-width: 1100px) {
    margin-right: calc(($colToggleWidth + 0.25rem) * -1);
  }

  @media (max-width: 1000px) {
    margin-right: calc($colToggleWidth / 2 + 0.125rem);
  }

  @media (max-width: $screen-md-max) {
    margin-right: 0;
  }

  &.no-toggle {
    margin-right: 0;

    @media (max-width: $screen-md-max) {
      padding-right: 0.5rem;
    }
  }

  .option-selector {
    margin-left: 25%; // col-3
    max-width: calc(100% - 25%);

    @media (max-width: $screen-md-max) {
      margin-right: calc($colToggleWidthMd);
    }

    @media (max-width: $screen-sm-max) {
      margin-right: calc($colToggleWidthSm);
    }

    @media (max-width: $screen-xs-max) {
      margin-right: calc($colToggleWidthXs);
    }

    &.no-toggle {
      margin-right: 0.125rem;
    }

    .title {
      font: normal normal normal 13px/16px "csr";
      font-style: italic;
    }

    .gx-0 {
      & > .col {
        padding: 0 5px;
      }
    }

    .selector-button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font: normal normal normal 14px/18px "csb";
      }

      .toggle {
        $size: 20px;
        width: $size;
        height: $size;
        display: flex;
        align-items: center;
        justify-content: center;
        @include shadow(5px);

        &.expand {
          @include shadowInset(5px);

          img {
            margin-top: -2px;
            margin-left: 0px;
            transform: rotate(180deg);
          }
        }
      }
    }

    select {
      font: normal normal normal 13px / 14px "csr";

      &.option {
        width: calc(200% + 60% + 10px);

        &.option-1 {
          margin-right: calc((200% + 10px) * -1);
          margin-left: -60%;
        }
        &.option-2 {
          margin-left: calc((100% + 60% + 10px) * -1);
          margin-right: -60%;
        }
      }

      option {
        font: normal normal normal 13px / 14px "csr";
        letter-spacing: 0px;
        color: $secondary;
        text-align: left;
        letter-spacing: 0px;

        &:hover {
          background-color: initial;
          color: initial;
        }

        &:nth-child(2n) {
          background-color: rgba($text-color-gray, 0.1);
        }
      }
    }
  }

  :deep(.options-container) {
    width: calc(100% - 25% - $colToggleWidth) !important;

    @media (max-width: $screen-md-max) {
      [class^="col"] {
        &:has(.result-column-container) {
          &.col-toggle {
            max-width: $colToggleWidthMd !important;

            .toggle {
              right: calc($colToggleWidthMd / 2) !important;
            }
          }
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      [class^="col"] {
        &:has(.result-column-container) {
          &.col-toggle {
            max-width: $colToggleWidthSm !important;

            .toggle {
              right: calc($colToggleWidthSm / 2) !important;
            }
          }
        }
      }
    }

    @media (max-width: $screen-xs-max) {
      [class^="col"] {
        &:has(.result-column-container) {
          &.col-toggle {
            max-width: $colToggleWidthXs !important;

            .toggle {
              right: calc($colToggleWidthXs / 2) !important;
            }
          }
        }
      }
    }
  }

  .col-toggle {
    width: $colToggleWidth;
    max-width: $colToggleWidth;

    &.expanded {
      &::before {
        left: -1px;
      }
    }

    @media (max-width: $screen-md-max) {
      width: $colToggleWidthMd;
      max-width: $colToggleWidthMd;
    }

    @media (max-width: $screen-sm-max) {
      width: $colToggleWidthSm;
      max-width: $colToggleWidthSm;
    }

    @media (max-width: $screen-xs-max) {
      width: $colToggleWidthXs;
      max-width: $colToggleWidthXs;
    }

    :deep(.toggle) {
      $size: 26px;
      z-index: 999;
      width: $size;
      height: $size;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -1rem;

      background-color: $color-1;
      @include shadow(50%);

      img {
        margin-top: 3px;
        margin-left: -1px;

        @media (max-width: $screen-xs-max) {
          margin-left: 0px;
        }
      }

      &.expand {
        img {
          margin-top: -2px;
          margin-left: -1px;
          transform: rotate(180deg);

          @media (max-width: $screen-xs-max) {
            margin-left: 0px;
          }
        }
      }

      @media (max-width: $screen-md-max) {
        right: calc(($colToggleWidthMd - $size) / 2);
      }

      @media (max-width: $screen-sm-max) {
        right: calc(($colToggleWidthSm - $size) / 2);
      }

      @media (max-width: $screen-xs-max) {
        right: calc(($colToggleWidthXs - $size) / 2);
      }
    }
  }
}

.tooltip-contents {
  @media (max-width: $screen-xs-max) {
    padding: 0 1rem;
    width: calc(100%);
  }
}

.print-button {
  @include shadow(5px);
}
</style>
