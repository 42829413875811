import objectHelper from "@/classes/helpers/objectHelper";

export default class UserInformations {
  salaireBrut = 3000;
  primes = 1000;
  idTrancheAge = 2;
  nombreEnfantsMoins27Ans = 1;
  isContractuel = true;

  constructor(data) {
    if (data) {
      objectHelper.assignOnlyExisting(this, data);
    }
  }

  getRemunerationBruteMensuelleTotale() {
    return this.salaireBrut + this.primes;
  }
}
