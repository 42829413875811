import garantiesService from "./garantiesService";
import prestationService from "./prestationService";
import * as GARANTIES from "@/classes/parametrage/garanties";
import * as OPTIONS from "@/classes/parametrage/options";
import { CSG_CRDS } from "@/classes/parametrage/global";

export default {
  getMaladie(userInput, idOption = OPTIONS.OBLIGATION_STATUTAIRE) {
    var result = {
      montant: 0,
      pourcentageCouverture: 0,
      ordinaire: this.getCongeMaladie(userInput, idOption, GARANTIES.MALADIE_ORDINAIRE),
      longueMaladieGraveMaladie: this.getCongeMaladie(userInput, idOption, GARANTIES.MALADIE_LONGUE_MALADIE_GRAVE),
      longueDuree: this.getCongeMaladie(userInput, idOption, GARANTIES.MALADIE_LONGUE_DUREE),
    };

    result.montant = result.ordinaire.montant + result.longueMaladieGraveMaladie.montant + result.longueDuree.montant;

    return result;
  },

  getObligationStatutaire(userInput, idGarantie, idCritere) {
    var tauxOption = garantiesService.getParametragesGaranties(GARANTIES.TYPE_MALADIE, idGarantie, OPTIONS.OBLIGATION_STATUTAIRE, idCritere);
    var tauxSalaire = tauxOption.find((x) => x.idTypeRemuneration == GARANTIES.TIB_NBI).tauxOption;
    var tauxPrimes = tauxOption.find((x) => x.idTypeRemuneration == GARANTIES.PRIMES_INDEMNITES).tauxOption;

    var coeffBrutNet = prestationService.getCoeffsBrutNet(userInput.isContractuel);
    var partSalaireBrut = (userInput.salaireBrut - userInput.primes) * tauxSalaire;
    var partSalaireNet = partSalaireBrut * coeffBrutNet.traitementDeBase;
    var partPrimesBrut = userInput.primes * tauxPrimes;
    var partPrimesNet = partPrimesBrut * coeffBrutNet.primes;

    return {
      partSalaireBrut: Math.floor(partSalaireBrut),
      partSalaireNet: Math.floor(partSalaireNet),
      partPrimesBrut: Math.floor(partPrimesBrut),
      partPrimesNet: Math.floor(partPrimesNet),
      totalBrut: Math.floor(partSalaireBrut + partPrimesBrut),
      totalNet: Math.floor(partSalaireNet + partPrimesNet),
    };
  },

  getOption(userInput, idOption, idGarantie, idCritere) {
    var obligationStatutaire = this.getObligationStatutaire(userInput, idGarantie, idCritere);

    var tauxOption = garantiesService.getParametragesGaranties(GARANTIES.TYPE_MALADIE, idGarantie, idOption, idCritere);
    var tauxSalaire = tauxOption.find((x) => x.idTypeRemuneration == GARANTIES.TIB_NBI).tauxOption;
    var tauxPrimes = tauxOption.find((x) => x.idTypeRemuneration == GARANTIES.PRIMES_INDEMNITES).tauxOption;

    var partSalaireBrut = (userInput.salaireBrut - userInput.primes) * tauxSalaire - obligationStatutaire.partSalaireBrut;
    var partSalaireNet = partSalaireBrut * CSG_CRDS;
    var partPrimesBrut = userInput.primes * tauxPrimes - obligationStatutaire.partPrimesBrut;
    var partPrimesNet = partPrimesBrut * CSG_CRDS;

    return {
      partSalaireBrut: Math.floor(partSalaireBrut),
      partSalaireNet: Math.floor(partSalaireNet),
      partPrimesBrut: Math.floor(partPrimesBrut),
      partPrimesNet: Math.floor(partPrimesNet),
      totalBrut: Math.floor(obligationStatutaire.totalBrut + (partSalaireBrut + partPrimesBrut)),
      totalNet: Math.floor(obligationStatutaire.totalNet + (partSalaireNet + partPrimesNet)),
    };
  },

  getCongeMaladie(userInput, idOption = OPTIONS.OBLIGATION_STATUTAIRE, idGarantie) {
    var result = undefined;
    var coeffBrutNet = prestationService.getCoeffsBrutNet(userInput.isContractuel);
    var salaireNet = (userInput.salaireBrut - userInput.primes) * coeffBrutNet.traitementDeBase;
    var primesNet = userInput.primes * coeffBrutNet.primes;
    var totalNet = salaireNet + primesNet;

    var obligationStatutaire = {
      pleinTraitement: this.getObligationStatutaire(userInput, idGarantie, GARANTIES.PLEIN_TRAITEMENT),
      demiTraitement: this.getObligationStatutaire(userInput, idGarantie, GARANTIES.DEMI_TRAITEMENT),
    };

    switch (idOption) {
      case OPTIONS.OBLIGATION_STATUTAIRE:
        result = {
          montant: obligationStatutaire.pleinTraitement.totalNet + obligationStatutaire.demiTraitement.totalNet,
          pleinTraitement: obligationStatutaire.pleinTraitement.totalNet,
          demiTraitement: obligationStatutaire.demiTraitement.totalNet,
        };
        break;

      case OPTIONS.SOCLE_INTERMINISTERIEL:
      case OPTIONS.OPTION_1:
      case OPTIONS.OPTION_2:
      case OPTIONS.OPTION_3:
        var remunerationOption = {
          pleinTraitement: this.getOption(userInput, idOption, idGarantie, GARANTIES.PLEIN_TRAITEMENT),
          demiTraitement: this.getOption(userInput, idOption, idGarantie, GARANTIES.DEMI_TRAITEMENT),
        };

        // Le total net ne peut pas excéder le salaire net de l'agent
        if (remunerationOption.pleinTraitement.totalNet > totalNet) {
          remunerationOption.pleinTraitement.totalNet = totalNet;
        }
        if (remunerationOption.demiTraitement.totalNet > totalNet) {
          remunerationOption.demiTraitement.totalNet = totalNet;
        }

        result = {
          montant: remunerationOption.pleinTraitement.totalNet + remunerationOption.demiTraitement.totalNet,
          pleinTraitement: remunerationOption.pleinTraitement.totalNet,
          demiTraitement: remunerationOption.demiTraitement.totalNet,
        };
        break;

      default:
        throw new Error("Garantie non prise en charge");
    }

    return result;
  },
};
