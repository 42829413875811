import * as TRANCHES_AGE from "@/classes/parametrage/tranchesAge";
import * as OPTIONS from "@/classes/parametrage/options";

export const PARAMETRAGE = [
  { idTrancheAge: TRANCHES_AGE.MOINS_25, idGarantie: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1.04 },
  { idTrancheAge: TRANCHES_AGE.MOINS_25, idGarantie: OPTIONS.OPTION_1, taux: 0.36 },
  { idTrancheAge: TRANCHES_AGE.MOINS_25, idGarantie: OPTIONS.OPTION_2, taux: 0.92 },
  { idTrancheAge: TRANCHES_AGE.MOINS_25, idGarantie: OPTIONS.OPTION_3, taux: 1.23 },

  { idTrancheAge: TRANCHES_AGE.DE_25_A_30, idGarantie: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1.15 },
  { idTrancheAge: TRANCHES_AGE.DE_25_A_30, idGarantie: OPTIONS.OPTION_1, taux: 0.41 },
  { idTrancheAge: TRANCHES_AGE.DE_25_A_30, idGarantie: OPTIONS.OPTION_2, taux: 1.02 },
  { idTrancheAge: TRANCHES_AGE.DE_25_A_30, idGarantie: OPTIONS.OPTION_3, taux: 1.37 },

  { idTrancheAge: TRANCHES_AGE.DE_30_A_35, idGarantie: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1.28 },
  { idTrancheAge: TRANCHES_AGE.DE_30_A_35, idGarantie: OPTIONS.OPTION_1, taux: 0.45 },
  { idTrancheAge: TRANCHES_AGE.DE_30_A_35, idGarantie: OPTIONS.OPTION_2, taux: 1.13 },
  { idTrancheAge: TRANCHES_AGE.DE_30_A_35, idGarantie: OPTIONS.OPTION_3, taux: 1.52 },

  { idTrancheAge: TRANCHES_AGE.DE_35_A_40, idGarantie: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1.42 },
  { idTrancheAge: TRANCHES_AGE.DE_35_A_40, idGarantie: OPTIONS.OPTION_1, taux: 0.5 },
  { idTrancheAge: TRANCHES_AGE.DE_35_A_40, idGarantie: OPTIONS.OPTION_2, taux: 1.26 },
  { idTrancheAge: TRANCHES_AGE.DE_35_A_40, idGarantie: OPTIONS.OPTION_3, taux: 1.69 },

  { idTrancheAge: TRANCHES_AGE.DE_40_A_45, idGarantie: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1.58 },
  { idTrancheAge: TRANCHES_AGE.DE_40_A_45, idGarantie: OPTIONS.OPTION_1, taux: 0.56 },
  { idTrancheAge: TRANCHES_AGE.DE_40_A_45, idGarantie: OPTIONS.OPTION_2, taux: 1.4 },
  { idTrancheAge: TRANCHES_AGE.DE_40_A_45, idGarantie: OPTIONS.OPTION_3, taux: 1.88 },

  { idTrancheAge: TRANCHES_AGE.DE_45_A_50, idGarantie: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 1.82 },
  { idTrancheAge: TRANCHES_AGE.DE_45_A_50, idGarantie: OPTIONS.OPTION_1, taux: 0.64 },
  { idTrancheAge: TRANCHES_AGE.DE_45_A_50, idGarantie: OPTIONS.OPTION_2, taux: 1.61 },
  { idTrancheAge: TRANCHES_AGE.DE_45_A_50, idGarantie: OPTIONS.OPTION_3, taux: 2.16 },

  { idTrancheAge: TRANCHES_AGE.DE_50_A_55, idGarantie: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 2.0 },
  { idTrancheAge: TRANCHES_AGE.DE_50_A_55, idGarantie: OPTIONS.OPTION_1, taux: 0.7 },
  { idTrancheAge: TRANCHES_AGE.DE_50_A_55, idGarantie: OPTIONS.OPTION_2, taux: 1.77 },
  { idTrancheAge: TRANCHES_AGE.DE_50_A_55, idGarantie: OPTIONS.OPTION_3, taux: 2.38 },

  { idTrancheAge: TRANCHES_AGE.DE_55_A_60, idGarantie: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 2.4 },
  { idTrancheAge: TRANCHES_AGE.DE_55_A_60, idGarantie: OPTIONS.OPTION_1, taux: 0.84 },
  { idTrancheAge: TRANCHES_AGE.DE_55_A_60, idGarantie: OPTIONS.OPTION_2, taux: 2.12 },
  { idTrancheAge: TRANCHES_AGE.DE_55_A_60, idGarantie: OPTIONS.OPTION_3, taux: 2.86 },

  { idTrancheAge: TRANCHES_AGE.PLUS_60, idGarantie: OPTIONS.SOCLE_INTERMINISTERIEL, taux: 2.4 },
  { idTrancheAge: TRANCHES_AGE.PLUS_60, idGarantie: OPTIONS.OPTION_1, taux: 0.84 },
  { idTrancheAge: TRANCHES_AGE.PLUS_60, idGarantie: OPTIONS.OPTION_2, taux: 2.12 },
  { idTrancheAge: TRANCHES_AGE.PLUS_60, idGarantie: OPTIONS.OPTION_3, taux: 2.86 },
];
