<template>
  <div class="jauge-container">
    <template v-if="widthInPercent">
      <div class="jauge-value" :style="'width:' + widthInPercent + '%'">&nbsp;</div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Jauge",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    widthInPercent() {
      return (this.value * 100) / this.maxValue;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

$height: 28px;
$borderRadius: 17px;

.jauge-container {
  height: $height;
  border-radius: $borderRadius;
  background-color: #efefef;
  overflow: hidden;

  .jauge-value {
    width: 0%;
    height: $height;
    min-width: $height !important;
    border-radius: $borderRadius;
    background-color: #1abf78;

    @include animate(slideInLeft);
  }
}

@media (max-width: $screen-xs-max) {
  $height: 1rem;
  $borderRadius: 0.5rem;

  .jauge-container {
    height: $height;
    border-radius: $borderRadius;

    .jauge-value {
      height: $height;
      min-width: $height !important;
      border-radius: $borderRadius;
    }
  }
}
</style>
