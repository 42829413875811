import garantiesService from "./garantiesService";
import * as OPTIONS from "@/classes/parametrage/options";
import * as GARANTIES from "@/classes/parametrage/garanties";

export default {
  getPensionInvalidite(userInput, idOption = OPTIONS.OBLIGATION_STATUTAIRE) {
    var idGarantie = userInput.isContractuel ? GARANTIES.INVALIDITE_CONTRACTUELS : GARANTIES.INVALIDITE_TITULAIRES;
    return this.getInvalidite(userInput, idOption, idGarantie);
  },

  getInvalidite(userInput, idOption = OPTIONS.OBLIGATION_STATUTAIRE, idGarantie) {
    var optionCategorie1 = garantiesService.getParametragesGaranties(GARANTIES.TYPE_INVALIDITE, idGarantie, idOption, GARANTIES.CATEGORIE_1);
    var categorie1TauxSalaire = optionCategorie1.find((x) => x.idTypeRemuneration == GARANTIES.TIB_NBI).tauxOption;
    var categorie1TauxPrimes = optionCategorie1.find((x) => x.idTypeRemuneration == GARANTIES.PRIMES_INDEMNITES).tauxOption;
    var optionCategorie2et3 = garantiesService.getParametragesGaranties(GARANTIES.TYPE_INVALIDITE, idGarantie, idOption, GARANTIES.CATEGORIE_2_3);
    var categorie2et3TauxSalaire = optionCategorie2et3.find((x) => x.idTypeRemuneration == GARANTIES.TIB_NBI).tauxOption;
    var categorie2et3TauxPrimes = optionCategorie2et3.find((x) => x.idTypeRemuneration == GARANTIES.PRIMES_INDEMNITES).tauxOption;

    var categorie1 = categorie1TauxSalaire * (userInput.salaireBrut - userInput.primes) + categorie1TauxPrimes * userInput.primes;
    var categorie2et3 = categorie2et3TauxSalaire * (userInput.salaireBrut - userInput.primes) + categorie2et3TauxPrimes * userInput.primes;

    return {
      montant: Math.floor(categorie1 + categorie2et3),
      categorie1: Math.floor(categorie1),
      categorie2et3: Math.floor(categorie2et3),
    };
  },
};
