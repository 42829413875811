<template>
  <div class="result-column-container" :class="cssClass">
    <!-- HEADER -->
    <div class="header">
      <slot name="header" />
    </div>

    <!-- CAPITAL DECES -->
    <div class="garantie">
      <slot name="capital-deces" />
    </div>

    <!-- MALADIE -->
    <div class="garantie">
      <slot name="maladie" />
    </div>

    <!-- INCAP/INVAL -->
    <div class="garantie">
      <slot name="invalidite" />
    </div>
  </div>
</template>

<script>
import objectHelper from "@/classes/helpers/objectHelper";

export default {
  name: "DisplayResult",
  data() {
    return {
      objectHelper,
    };
  },
  props: {
    cssClass: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

.result-column-container {
  $radius: 10px;
  $topBottom: 20px;
  $leftRight: 7px;
  $headerRowSize: 70px;
  $headerRowSizeXs: 65px;

  z-index: 1;
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
  }

  &.base {
    z-index: 0;

    &.result-table {
      margin-top: $headerRowSize;

      @media (max-width: $screen-xs-max) {
        margin-top: $headerRowSizeXs;
      }
    }

    &:before {
      top: 0;
      right: $leftRight;
      bottom: -$topBottom;
      left: $leftRight;
      z-index: -100;
      background-color: transparent;
      border: 1px solid rgba(gray, 0.5);
      border-radius: 5px;
    }

    .header {
      :deep(.line) {
        text-align: center;
      }
    }

    :deep(.line) {
      color: #848282 !important;

      .montant {
        color: #848282 !important;
      }
    }
  }

  &.socle {
    &:before {
      top: 0;
      right: $leftRight;
      bottom: -$topBottom;
      left: $leftRight;
      z-index: -99;
      background-color: white;
      @include shadow();
      border-radius: 5px;
    }

    :deep(.line) {
      padding: 0 $leftRight;
    }

    .header {
      :deep(.line) {
        text-align: center;
        padding: 0 $leftRight;

        @media (max-width: $screen-xs-max) {
          padding: 0 0.5rem;
          font: normal normal normal 13px/16px "csb";
        }
      }
    }
  }

  :deep(.line) {
    min-height: 68px;
    position: relative;

    @media (max-width: $screen-xs-max) {
      min-height: $headerRowSizeXs;
    }

    &::before {
      content: "";
      position: absolute;
      pointer-events: none;
      z-index: -1000;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.start {
      padding-right: 1rem;

      &::before {
        border-radius: $radius 0 0 $radius;
      }

      @media (max-width: $screen-xs-max) {
        padding-right: 0.5rem;
      }
    }

    &.end {
      &::before {
        border-radius: 0 $radius $radius 0;
        left: -1px;

        @media (max-width: $screen-xs-max) {
          border-radius: 0 !important;
          right: -1rem;
        }
      }
    }

    &.text-right {
      padding-right: 1rem;
      justify-content: flex-end !important;
    }
  }

  .header {
    :deep(.line) {
      display: flex;
      align-items: center;
      justify-content: center;

      height: $headerRowSize;
      color: $color-4;
      font: normal 16px "csb";

      @media (max-width: $screen-xs-max) {
        height: $headerRowSizeXs !important;
        padding: 0 0.5rem;
        font: normal normal normal 13px/16px "csb";
      }

      &.start {
        flex-direction: column;
        align-items: flex-end;

        &.text-right {
          align-items: flex-start;
        }

        @media (max-width: $screen-xs-max) {
          padding-left: 0.75rem;
          align-items: flex-start;

          br {
            display: none !important;
          }
        }
      }

      .option-name {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 0.5rem;

        @media (min-width: $screen-sm) {
          small {
            font-size: 75%;
          }
        }

        @media (max-width: $screen-xs-max) {
          margin-top: 1rem;

          small {
            // text-wrap: nowrap !important;
          }
        }
      }

      .option {
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        padding: 0 0.75rem;
        border-radius: 20px;
        color: white;
        font: normal 16px "csb";

        @media (max-width: $screen-xs-max) {
          font: normal normal normal 13px/26px "csb";
        }

        &.option_1 {
          background-color: $color-5;
        }
        &.option_2 {
          background-color: $color-3;
        }
        &.option_3 {
          background-color: $color-2;
        }
      }

      .montant {
        color: black;
        font: normal 30px "csb";

        @media (max-width: $screen-xs-max) {
          font: normal normal normal 22px/26px "csb";
        }
      }

      .jauge-container {
        width: 100%;
        margin: 0 1rem;
      }
    }
  }

  .garantie {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    :deep(.line) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font: normal 16px "csr";
      margin-bottom: 8px;

      &::before {
        // background-color: rgba($color-1, 0.03);
        background-color: rgb(247 249 251);
      }

      &.start {
        flex-direction: row;
        justify-content: space-between;
        padding-left: 1.5rem;

        @media (max-width: $screen-xs-max) {
          padding-left: 1rem;
          font: normal normal normal 14px/16px "csb";

          &::before {
            border-radius: 0;
          }
        }
      }

      .montant {
        font-size: 16px;

        &.small {
          font: normal 14px "csr" !important;
        }

        @media (max-width: $screen-xs-max) {
          font: normal normal normal 18px/26px "csb";

          &.small {
            font: normal 13px "csr" !important;
          }
        }
      }

      &.title {
        color: $color-4;
        font: normal normal normal 16px/18px "csb";

        @media (max-width: $screen-xs-max) {
          font: normal normal normal 14px/16px "csb";
        }

        &::before {
          z-index: -1;
          // background-color: rgba($color-1, 0.1);
          background-color: rgb(229 236 243);
        }

        .montant {
          font-size: 26px;

          @media (max-width: $screen-xs-max) {
            font: normal normal normal 18px/26px "csb";
          }
        }

        small {
          font: normal normal normal 14px/18px "csr";

          @media (max-width: $screen-xs-max) {
            font: inherit;
          }
        }
      }

      &.subtitle {
        color: black;
        font: normal normal normal 16px/20px "csb";

        @media (max-width: $screen-xs-max) {
          min-height: 68px !important;
          font: normal normal normal 14px/16px "csb";
        }

        &::before {
          background-color: transparent;
        }
      }
    }
  }

  &.no-toggle {
    :deep(.line) {
      &:before {
        right: -0.5rem !important;

        @media (min-width: $screen-xs-max) {
          border-radius: 0 $radius $radius 0;
        }
      }
    }
  }

  &.result-example {
    margin-top: calc($headerRowSize + 22px);

    @media (max-width: $screen-xs-max) {
      margin-top: calc($headerRowSizeXs + 22px);
    }

    &:before {
      top: -$topBottom !important;
    }
  }
}
</style>
