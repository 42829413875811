<template>
  <div class="col options-container">
    <div class="row gx-0">
      <template v-for="(resultat, index) in resultats" :key="resultat">
        <template v-if="choosenOptions.includes(index) && objectHelper.isDefined(resultat.garantie) && resultats.some((x) => x.garantie.id == resultat.garantie.id)">
          <Transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
            <div class="col">
              <DisplayResultColumn :cssClass="resultat.garantie.cssClass + getCssClass(index) + ' result-table'">
                <template v-slot:header>
                  <template v-if="resultat.garantie.id > OPTIONS.OBLIGATION_STATUTAIRE">
                    <div class="line">
                      <div class="option-name"><div>Statutaire + socle interministériel</div></div>
                    </div>
                  </template>
                  <div class="line">
                    <template v-if="resultat.garantie.id == OPTIONS.OBLIGATION_STATUTAIRE">
                      <div class="option-name">
                        <div>Régime<br />statutaire</div>
                        <small>(obligation employeur)</small>
                      </div>
                    </template>
                    <template v-else-if="resultat.garantie.id > OPTIONS.SOCLE_INTERMINISTERIEL">
                      <div class="option" :class="'option_' + (resultat.garantie.id - 1)">+ Option {{ resultat.garantie.id - 1 }}</div>
                    </template>
                    <template v-else>&nbsp;</template>
                  </div>
                  <div class="line">
                    <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).cotisationMensuelleApresEmployeur) }}</div>
                  </div>
                  <div class="line">
                    <template v-if="resultat.garantie.id >= OPTIONS.OBLIGATION_STATUTAIRE">
                      <Jauge :value="index + 1" :maxValue="5" />
                    </template>
                    <template v-else>&nbsp;</template>
                  </div>
                </template>
                <template v-slot:capital-deces>
                  <div class="line title">&nbsp;</div>

                  <template v-if="expandCapitalDeces">
                    <div class="line subtitle">
                      <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).capitalDeces.montant) }}</div>
                      <!-- 
                      <div class="montant">{{ objectHelper.formatPercent(getResultat(resultat.garantie.id).capitalDeces.pourcentageCouverture) }}</div>
                      <div class="montant small">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).capitalDeces.montant) }}</div> 
                      -->
                    </div>
                    <template v-if="objectHelper.isDefined(userInput) && userInput.nombreEnfantsMoins27Ans > 0">
                      <div class="line subtitle">&nbsp;</div>
                      <div class="line">
                        <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).capitalDeces.renteEducation0a18) }}</div>
                      </div>
                      <div class="line">
                        <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).capitalDeces.renteEducation19a27) }}</div>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-slot:maladie>
                  <div class="line title">
                    &nbsp;
                    <!-- <div class="montant">{{ objectHelper.formatPercent(getResultat(resultat.garantie.id).maladie.pourcentageCouverture) }}</div> -->
                  </div>

                  <template v-if="expandMaladie">
                    <div class="line subtitle"></div>
                    <div class="line">
                      <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).maladie.ordinaire.pleinTraitement) }}</div>
                    </div>
                    <div class="line">
                      <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).maladie.ordinaire.demiTraitement) }}</div>
                    </div>

                    <div class="line subtitle"></div>
                    <div class="line">
                      <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).maladie.longueMaladieGraveMaladie.pleinTraitement) }}</div>
                    </div>
                    <div class="line">
                      <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).maladie.longueMaladieGraveMaladie.demiTraitement) }}</div>
                    </div>

                    <template v-if="!userInput.isContractuel">
                      <div class="line subtitle"></div>
                      <div class="line">
                        <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).maladie.longueDuree.pleinTraitement) }}</div>
                      </div>
                      <div class="line">
                        <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).maladie.longueDuree.demiTraitement) }}</div>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-slot:invalidite>
                  <div class="line title">
                    &nbsp;
                    <!-- <div class="montant">{{ objectHelper.formatPercent(getResultat(resultat.garantie.id).invalidite.pourcentageCouverture) }}</div> -->
                  </div>

                  <template v-if="expandInvalidite">
                    <!-- <div class="line subtitle"></div> -->
                    <div class="line">
                      <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).invalidite.categorie1) }}</div>
                    </div>
                    <div class="line">
                      <div class="montant">{{ objectHelper.formatMoney(getResultat(resultat.garantie.id).invalidite.categorie2et3) }}</div>
                    </div>
                  </template>
                </template>
              </DisplayResultColumn>
            </div>
          </Transition>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import objectHelper from "@/classes/helpers/objectHelper";
import UserInformations from "@/classes/dto/userInformations";
import * as OPTIONS from "@/classes/parametrage/options";
import Jauge from "./Jauge.vue";
import DisplayResultColumn from "./DisplayResultColumn.vue";

export default {
  name: "DisplayResultOptions",
  components: { DisplayResultColumn, Jauge },
  data() {
    return {
      OPTIONS,
      objectHelper,
    };
  },
  props: {
    userInput: {
      type: UserInformations,
      default: new UserInformations(),
    },
    resultats: {
      type: Array,
      default: undefined,
    },
    choosenOptions: {
      type: Array,
      default: undefined,
    },
    showToggleColumn: {
      type: Boolean,
    },
    expandCapitalDeces: {
      type: Boolean,
    },
    expandMaladie: {
      type: Boolean,
    },
    expandInvalidite: {
      type: Boolean,
    },
  },
  methods: {
    getResultat(idGarantie) {
      return this.resultats.find((x) => x.garantie.id == idGarantie);
    },
    getCssClass(index) {
      return !this.showToggleColumn && index == this.choosenOptions.length - 1 ? " no-toggle" : "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";
</style>
