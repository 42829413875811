export const OBLIGATION_STATUTAIRE = 0;
export const SOCLE_INTERMINISTERIEL = 1;
export const OPTION_1 = 2;
export const OPTION_2 = 3;
export const OPTION_3 = 4;

export const PARAMETRAGE = [
  { id: OBLIGATION_STATUTAIRE, name: "régime statutaire (obligation employeur)", cssClass: "base" },
  { id: SOCLE_INTERMINISTERIEL, name: "statutaire + socle interministériel", cssClass: "socle" },
  { id: OPTION_1, name: "statutaire + socle interministériel + option 1", cssClass: "socle option_1 star" },
  { id: OPTION_2, name: "statutaire + socle interministériel + option 2", cssClass: "socle option_2" },
  { id: OPTION_3, name: "statutaire + socle interministériel + option 3", cssClass: "socle option_3" },
];
