// import objectHelper from "@/classes/helpers/objectHelper";
import * as GARANTIES from "@/classes/parametrage/garanties";

export default {
  /*
   * Récupération du paramétrage des garanties pour un type de garantie donnée triée par un critère donné pour une option donnée
   */
  getParametragesGaranties(idType, idGarantie, idOption, idCritere) {
    var parametrages = GARANTIES.PARAMETRAGE.types
      .find((x) => x.idType == idType)
      .garanties.filter((x) => x.idGarantie == idGarantie)
      .filter((x) => x.idCritere == idCritere);

    parametrages.forEach((p) => {
      p.tauxOption = p.parametrage.find((x) => x.idOption == idOption).taux;
    });

    return parametrages;
  },
};
