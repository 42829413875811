import cotisationService from "./cotisationService";
import capitalDecesService from "./capitalDecesService";
import maladieService from "./maladieService";
import invaliditeService from "./invaliditeService";

import * as GLOBAL from "@/classes/parametrage/global";
import * as OPTIONS from "@/classes/parametrage/options";

import ResultatSimulation from "../dto/resultatSimulation";

export default {
  compute(userInput) {
    var result = [];

    OPTIONS.PARAMETRAGE.forEach((garantie) => {
      var resultatSimulation = new ResultatSimulation();

      resultatSimulation.pourcentageCouverture = 0;
      resultatSimulation.garantie = garantie;
      resultatSimulation.capitalDeces = capitalDecesService.getCapitalDeces(userInput, garantie.id);
      resultatSimulation.maladie = maladieService.getMaladie(userInput, garantie.id);
      resultatSimulation.invalidite = invaliditeService.getPensionInvalidite(userInput, garantie.id);

      result.push(resultatSimulation);
    });

    result = this.processPourcentagesCouverture(result);
    result = this.processCotisationMensuelle(result, userInput);

    return result;
  },

  /*
   * Calcul du pourcentage de couverture des différentes options après calcul complet
   */
  processPourcentagesCouverture(resultatsSimulation) {
    function getPourcentageCouverture(valeurObligationStatutaire, valeurResultat) {
      return (valeurResultat * 100) / valeurObligationStatutaire;
    }

    resultatsSimulation.forEach((resultat) => {
      var isObligationStatutaire = resultat.garantie.id == OPTIONS.OBLIGATION_STATUTAIRE;
      if (isObligationStatutaire) {
        resultat.capitalDeces.pourcentageCouverture = 100;
        resultat.maladie.pourcentageCouverture = 100;
        resultat.maladie.ordinaire.pourcentageCouverture = 100;
        resultat.maladie.longueMaladieGraveMaladie.pourcentageCouverture = 100;
        resultat.maladie.longueDuree.pourcentageCouverture = 100;
        resultat.invalidite.pourcentageCouverture = 100;
      } else {
        var obligationStatutaire = resultatsSimulation.find((x) => x.garantie.id == OPTIONS.OBLIGATION_STATUTAIRE);

        resultat.capitalDeces.pourcentageCouverture = getPourcentageCouverture(obligationStatutaire.capitalDeces.montant, resultat.capitalDeces.montant);
        resultat.maladie.pourcentageCouverture = getPourcentageCouverture(obligationStatutaire.maladie.montant, resultat.maladie.montant);
        resultat.maladie.ordinaire.pourcentageCouverture = getPourcentageCouverture(obligationStatutaire.maladie.ordinaire.montant, resultat.maladie.ordinaire.montant);
        resultat.maladie.longueMaladieGraveMaladie.pourcentageCouverture = getPourcentageCouverture(obligationStatutaire.maladie.longueMaladieGraveMaladie.montant, resultat.maladie.longueMaladieGraveMaladie.montant);
        resultat.maladie.longueDuree.pourcentageCouverture = getPourcentageCouverture(obligationStatutaire.maladie.longueDuree.montant, resultat.maladie.longueDuree.montant);
        resultat.invalidite.pourcentageCouverture = getPourcentageCouverture(obligationStatutaire.invalidite.montant, resultat.invalidite.montant);
      }
    });

    return resultatsSimulation;
  },

  /*
   * Calcul de la cotisation mensuelle des différentes options après calcul complet
   */
  processCotisationMensuelle(resultatsSimulation, userInput) {
    resultatsSimulation.forEach((resultat) => {
      var isObligationStatutaire = resultat.garantie.id == OPTIONS.OBLIGATION_STATUTAIRE;
      var isSocleInterministeriel = resultat.garantie.id == OPTIONS.SOCLE_INTERMINISTERIEL;

      var cotisationSocleInterministeriel = cotisationService.getTauxCotisations(userInput.idTrancheAge, OPTIONS.SOCLE_INTERMINISTERIEL) * userInput.salaireBrut;

      if (isObligationStatutaire) {
        resultat.cotisationMensuelle = 0;
        resultat.cotisationMensuelleApresEmployeur = 0;
      } else {
        if (isSocleInterministeriel) {
          resultat.cotisationMensuelle = cotisationSocleInterministeriel;
        } else {
          resultat.cotisationMensuelle = cotisationSocleInterministeriel + cotisationService.getTauxCotisations(userInput.idTrancheAge, resultat.garantie.id) * userInput.salaireBrut;
        }

        resultat.cotisationMensuelleApresEmployeur = resultat.cotisationMensuelle - GLOBAL.PARTICIPATION_EMPLOYEUR;
      }
    });

    return resultatsSimulation;
  },
};
