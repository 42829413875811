import prestationService from "./prestationService";
import * as OPTIONS from "@/classes/parametrage/options";

export default {
  getCapitalDeces(userInput, idOption = OPTIONS.OBLIGATION_STATUTAIRE) {
    var donneesPrestation = prestationService.getDonneesPrestation(userInput.isContractuel);

    function capitalDeces() {
      var result = 0;

      switch (idOption) {
        case OPTIONS.OBLIGATION_STATUTAIRE:
          result = (1 * (userInput.salaireBrut - userInput.primes) + 1 * userInput.primes) * 12;
          break;

        case OPTIONS.SOCLE_INTERMINISTERIEL:
        case OPTIONS.OPTION_1:
          result = (2 * (userInput.salaireBrut - userInput.primes) + 2 * userInput.primes) * 12;
          break;

        case OPTIONS.OPTION_2:
          result = (2.5 * (userInput.salaireBrut - userInput.primes) + 2.5 * userInput.primes) * 12;
          break;

        case OPTIONS.OPTION_3:
          result = (3 * userInput.salaireBrut + 0.5 * userInput.salaireBrut * userInput.nombreEnfantsMoins27Ans) * 12;
          break;

        default:
          throw new Error("Garantie non prise en charge");
      }

      // Le montant du capital décès ne peut pas être inférieur à 4 fois un montant défini par décret
      if (result < donneesPrestation.montantMiniCapitalDeces) {
        result = donneesPrestation.montantMiniCapitalDeces;
      }

      return result;
    }

    function renteEducation0a18() {
      var result = 0;

      if (userInput.nombreEnfantsMoins27Ans > 0) {
        switch (idOption) {
          case OPTIONS.OBLIGATION_STATUTAIRE:
          case OPTIONS.SOCLE_INTERMINISTERIEL:
          case OPTIONS.OPTION_1:
            result = 0.05 * donneesPrestation.pmss;
            break;

          case OPTIONS.OPTION_2:
            result = 0.075 * donneesPrestation.pmss;
            break;

          case OPTIONS.OPTION_3:
            result = 0.1 * donneesPrestation.pmss;
            break;

          default:
            throw new Error("Garantie non prise en charge");
        }
      }

      return result;
    }

    function renteEducation19a27() {
      var result = 0;

      if (userInput.nombreEnfantsMoins27Ans > 0) {
        switch (idOption) {
          case OPTIONS.OBLIGATION_STATUTAIRE:
          case OPTIONS.SOCLE_INTERMINISTERIEL:
          case OPTIONS.OPTION_1:
            result = 0.15 * donneesPrestation.pmss;
            break;

          case OPTIONS.OPTION_2:
            result = 0.2 * donneesPrestation.pmss;
            break;

          case OPTIONS.OPTION_3:
            result = 0.25 * donneesPrestation.pmss;
            break;

          default:
            throw new Error("Garantie non prise en charge");
        }
      }

      return result;
    }

    return {
      pourcentageCouverture: 0,
      montant: Math.round(capitalDeces()),
      renteEducation0a18: Math.round(renteEducation0a18()),
      renteEducation19a27: Math.round(renteEducation19a27()),
    };
  },
};
