import objectHelper from "@/classes/helpers/objectHelper";

export default class ResultatSimulation {
  garantie;

  cotisationMensuelle = 0;
  cotisationMensuelleApresEmployeur = 0;

  capitalDeces = {
    montant: 0,
    pourcentageCouverture: 0,
    renteEducation0a18: 0,
    renteEducation19a27: 0,
  };

  maladie = {
    pourcentageCouverture: 0,
    ordinaire: {
      pleinTraitement: 0,
      demiTraitement: 0,
    },
    longueMaladieGraveMaladie: {
      pleinTraitement: 0,
      demiTraitement: 0,
    },
    longueDuree: {
      pleinTraitement: 0,
      demiTraitement: 0,
    },
  };

  invalidite = {
    pourcentageCouverture: 0,
    categorie1: 0,
    categorie2et3: 0,
  };

  constructor(data) {
    if (data) {
      objectHelper.assignOnlyExisting(this, data);
    }
  }
}
