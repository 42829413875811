import objectHelper from "@/classes/helpers/objectHelper";

import * as PRESTATIONS from "@/classes/parametrage/prestations";
export default {
  /*
   * Récupération du paramétrage des cotisations correspondant au type de profil (fonctionnaire/contractuel)
   */
  getCoeffsBrutNet(isContractuel) {
    var result = {
      traitementDeBase: 0,
      primes: 0,
    };

    var idProfil = isContractuel ? PRESTATIONS.CONTRACTUEL : PRESTATIONS.FONCTIONNAIRE;
    var parametrages = PRESTATIONS.PARAMETRAGE_REMUNERATIONS.filter((x) => x.idProfil == idProfil);
    if (objectHelper.arrayIsNotEmpty(parametrages)) {
      if (parametrages.some((x) => x.idRemuneration == PRESTATIONS.BASE)) {
        result.traitementDeBase = parametrages.find((x) => x.idRemuneration == PRESTATIONS.BASE).coeffBrutNet;
      }
      if (parametrages.some((x) => x.idRemuneration == PRESTATIONS.PRIMES)) {
        result.primes = parametrages.find((x) => x.idRemuneration == PRESTATIONS.PRIMES).coeffBrutNet;
      }
    }

    return result;
  },

  /*
   * Récupération du paramétrage des données de prestation correspondant au type de profil (fonctionnaire/contractuel)
   */
  getDonneesPrestation(isContractuel) {
    var result = {
      pmss: 0,
      montantMiniCapitalDeces: 0,
    };

    var idProfil = isContractuel ? PRESTATIONS.CONTRACTUEL : PRESTATIONS.FONCTIONNAIRE;
    var parametrage = PRESTATIONS.PARAMETRAGE_PRESTATIONS.find((x) => x.idProfil == idProfil);
    if (objectHelper.isDefined(parametrage)) {
      result = parametrage;
    }

    return result;
  },
};
