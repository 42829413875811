export const MOINS_25 = 0;
export const DE_25_A_30 = 1;
export const DE_30_A_35 = 2;
export const DE_35_A_40 = 3;
export const DE_40_A_45 = 4;
export const DE_45_A_50 = 5;
export const DE_50_A_55 = 6;
export const DE_55_A_60 = 7;
export const PLUS_60 = 8;

export const TRANCHES_AGE = [
  { id: MOINS_25, name: "moins de 25 ans" },
  { id: DE_25_A_30, name: "de 25 à 29 ans" },
  { id: DE_30_A_35, name: "de 30 à 34 ans" },
  { id: DE_35_A_40, name: "de 35 à 39 ans" },
  { id: DE_40_A_45, name: "de 40 à 44 ans" },
  { id: DE_45_A_50, name: "de 45 à 49 ans" },
  { id: DE_50_A_55, name: "de 50 à 54 ans" },
  { id: DE_55_A_60, name: "de 55 à 59 ans" },
  { id: PLUS_60, name: "à partir de 60 ans" },
];
